import { Container,Row,Col,Card,CardGroup, Button } from "react-bootstrap";
import {IoPricetag,IoStatsChart,IoRocketSharp} from "react-icons/io5";
import { IconContext } from "react-icons/lib";
import {FaHandHoldingUsd,FaMoneyBill} from 'react-icons/fa';
import { useState } from "react";
import {AiFillDollarCircle} from 'react-icons/ai';
import stakingIce1 from "../assets/images/snow/ice 3.png";
import stakingIce2 from "../assets/images/snow/6.png";
import stakingIce3 from "../assets/images/snow/7.png";
import spooky from "../assets/images/spookyhaloweenfloki.png";
import logo from '../assets/images/logo.png'
import sflogo from '../assets/images/oldsflogo.png'
import santa from '../assets/images/santagif.gif';
import spookygif from '../assets/images/spookygif.gif';
import sf from '../assets/images/sf.gif';

const StakingCards = () => {

    const [option, setOption] = useState({
        dataLabels:{enabled:false},
        grid:{show:false},
        tooltip:{enabled:false},
        chart:{toolbar:{show:false}},
        xaxis:{labels:{show:false},crosshairs:{show:false},axisBorder:{show:false},axisTicks:{show:false}},
        yaxis:{labels:{show:false}},
        fill:{colors:['#fe0000']},
        stroke:{colors:['#fe0000']}
     })

    return(
        <Container style={{marginTop:'5px',height:"80vh",overflow:"scroll"}}>
            <Row>

                <Col xl>
                    <Card className="statscard1">
                        <img className="stakingIce3" src={stakingIce3}/>
                    <Card.Title style={{fontSize:"30px",fontWeight:"bold",textAlign:"center",  fontFamily: "jumper", borderBottom:"#db9f2e 2px solid",paddingBottom:"15px"}}>
                         <img src={sflogo} style={{marginRight:"10px",height:"50px"}}/>
                        Santa Floki <p style={{fontSize:15,marginLeft:'10%',marginTop:'-3%'}}>(Orignal Eddition)</p>
                        </Card.Title>
                        <Card.Text style={{textAlign:"center" , marginTop:"10px"}}>
                        <img style={{width:"200px" , borderRadius:"10%",color:"red"}} src={sf}/>
                        </Card.Text>
                        <Card.Text style={{fontSize:"20px" , textAlign:"center" , marginTop:"10px"}}>
                            <Button className="btn btn-warning" href="https://santafloki.com/staking/" target="_blank" style={{backgroundColor:"yello"}}>Stake NFTs!</Button>
                        </Card.Text>
                    </Card>
                </Col>

                <Col xl>
                    <Card className="statscard1">
                    <img className="stakingIce1" src={stakingIce1}/>
                    <Card.Title style={{fontSize:"25px",fontWeight:"bold",textAlign:"center",  fontFamily: "jumper", borderBottom:"#db9f2e 2px solid",paddingBottom:"15px"}}>
                         <img src={logo} style={{marginRight:"10px",height:"50px"}}/>
                        Santa Floki <p style={{fontSize:15,marginLeft:'15%',marginTop:'-3%'}}>(Anniversary Eddition)</p>                   
                        </Card.Title>
                        <Card.Text style={{textAlign:"center" , marginTop:"10px"}}>
                        <img style={{width:"167px" , borderRadius:"10%"}} src={santa}/>
                        </Card.Text>
                        <Card.Text style={{fontSize:"20px" , textAlign:"center" , marginTop:"10px"}}>
                            <Button className="btn btn-warning" href="https://staking.santafloki.com/" target="_blank" style={{backgroundColor:"yello"}}>Stake NFTs!</Button>
                        </Card.Text>
                    </Card>
                </Col>

                </Row>
            
            </Container>
    );
}
export default StakingCards;