import {Alert, Container, Row} from 'react-bootstrap'
import NavbarComponent from './NavbarComponent';
import { useState } from 'react';
import { useContext } from 'react';
import { Context } from '../App';
import { useEffect } from 'react';
import Logo from './Logo';
import gamelogo from "../assets/images/xverse.jpeg";
import gameice from "../assets/images/snow/6.png";
import bgimg from "../assets/images/bgimg.png";
import Btn from "./Btn";
import { FaWindows } from "react-icons/fa";

const Xverse = () => {
    const [provider,setProvider] = useState(null);
    const [toggle,setToggle,connected,setConnected,address,setAddress] = useContext(Context);
    const [price,setPrice]= useState("...");
    
    useEffect(()=>{
        console.log("wow from connected");
    },[connected]);
    
    function connectionCallback(provider,address){
        setProvider(provider);
        setAddress(address);
    }


    return(
        <div className="homediv">
            <img className="bgimg" src={bgimg}/>
            <NavbarComponent/>
           
            <Container style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',width:'100%'}} className='homecontainer text'>
                <h1 className='pagebanner'>
                {/* <img className='gameice' src={gameice}/> */}
                    <Logo size={40} marginRight={10}/>
                         <div className='vline'/>X-Verse

                </h1>
            <img  style={{width:'400px',marginTop:'5%'}} src={gamelogo}/>
            <h5 style={{marginTop:'5%'}}>Download</h5>
        <a href='https://drive.google.com/file/d/1IntI9uT2NpzHPtKZO0Mao8d5OQYqeVNS/view?usp=sharing'><FaWindows style={{color:'#00A4EF',width:'100px',height:'100px',marginTop:'5%'}}/></a>
            </Container>
            
        </div>
    );
}
export default Xverse;