import { Alert, Button, Container, Row} from 'react-bootstrap'
import NavbarComponent from './NavbarComponent';
import {Col,Card,CardGroup } from "react-bootstrap";
import {CgDanger} from 'react-icons/cg'
import {FaReact} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import Chart from 'apexcharts'
import Logo from './Logo';
import bgimg from "../assets/images/bgimg.png";
import santa from '../assets/images/santagif.gif';
import snow from "../assets/images/snow/4.png";
import SantaNftCards from './SantaNftCards';
import Nftbtn from './Nftbtn';
import { flexbox } from '@mui/system';

const Minting = () =>{
    return(
        <div className="homediv">
                        <img className="bgimg" src={bgimg}/>
            <NavbarComponent/>
            <Container className='homecontainer text'>

                <h1 className='pagebanner'>
                    
                <Logo size={50} marginRight={10}/>
                     <div className='vline'/>Minting
                </h1>

            <Col>
                    <Card className="statscard1">
                    <Card.Title style={{fontSize:"30px",fontWeight:"bold",textAlign:"center",  fontFamily: "jumper", borderBottom:"#db9f2e 2px solid",paddingBottom:"15px"}}>
                        <img className='mintingSnow' src={snow}/>
                            SantaFloki Anniversary Edition NFTs                       
                        </Card.Title>
                        <Card.Text style={{textAlign:"center" , marginTop:"10px"}}>
                        <img style={{width:"167px" , borderRadius:"10%"}} src={santa}/>
                        </Card.Text>
                        <Card.Text style={{fontSize:"20px" , textAlign:"center" , marginTop:"10px"}}>
                        <button onClick={()=>{window.open('https://minting.santafloki.com/','_blank')}} className='btn btn-warning'>Mint Now!</button>
                        </Card.Text>
                    </Card>
                </Col>

            </Container>
            {/* <div className='center'>
                <div className='cardmint'>
                <h4 className='text' style={{fontFamily:"jumper"}}>SantaFloki Anniversary Edition NFTs</h4>
                <img style={{width:"200px" , borderRadius:"10%"}} src={santa}/>
                <div style={{marginTop:'15px'}}>
                <button onClick={()=>{window.open('https://minting.santafloki.com/','_blank')}} className='btn btn-warning'>Mint Now!</button>
                </div>
                </div>
            </div> */}
            
        </div>
    );
}

export default Minting;
